h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  line-height: 1.5;
  font-family: $font__heading;
  margin: 0.5em 0;
}
