/*
* Usage: https://smacss.com/book/type-module
**/

.example {
  padding: 15px;
  margin-bottom: 10px;
  border: 3px #f5f5f5 solid;
  border-radius: 10px;

  :last-child {
    margin-bottom: 0;
  }
}

.infobox {
  padding: 15px;
}

.lede {
  @include font-size(2);
}

header {
  border-bottom: 1px #ccc solid;
}

.grid-demo-col {
  background: #e0e0e0;
  border: 1px #bbb solid;
  // border-width: 1px 0;
  text-align: center;
}
.grid-demo-inner {
  background: #eee;
}

.palette {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    display: inline-block;
    min-width: 150px;
    padding: 10px;
    border: 2px #000 solid;
    text-align: center;

    &--text {
      background: #000;
      color: #fff;
    }
  }
}

.social-icons {
  list-style: none;
  padding: 0;

  &__item {
    @include font-size(2);
    display: inline-block;

    & + & {
      margin-left: 10px;
    }
  }
}

.fa {
  color: #fff;
  display: inline-block;
  width: 32px;
  height: 32px;
  padding-top: 6px;
  padding-right: 1px;
  border-radius: 100%;
  vertical-align: middle;
  text-align: center;

  &-facebook {
    background: #3b5998;
  }
  &-instagram {
    background: #000;
  }
  &-twitter {
    background: #6cadde;
  }
  &-google-plus {
    background: #dc4e41;
  }
}
