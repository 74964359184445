// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
  font-size: ($sizeValue * 16) * 1px;
  font-size: $sizeValue * 1rem;
}

// Center block
@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Clearfix
@mixin clearfix() {
  content: "";
  display: table;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
  clear: both;
}

@mixin block-vcenter() {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin fa() {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0)
}