a {
  color: $color__link;
  // text-decoration: underline;

  &:visited {
    color: $color__link-visited;
  }
  &:hover,
  &:focus,
  &:active {
    color: $color__link-hover;
  }
  &:focus {
    outline: thin dotted;
  }
  &:hover,
  &:active {
    outline: 0;
  }
}

.btn {
  &-primary {
    &:visited {
      color: #fff;
    }
  }
}
