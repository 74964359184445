$font__main: 'Arial', sans-serif;
$font__heading: 'Arial', serif;
$font__code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", Courier, monospace;
$font__line-height-body: 1.5;
$font__line-height-pre: 1.6;
@mixin font__base-size() {
  @media (max-width: $break__xs) {
    @include font-size(1.4);
  }
  @media (min-width: $break__xs) {
    @include font-size(1.4);
  }
}
