input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {
  color: $color__text-input;
  border: 1px solid $color__border-input;
  border-radius: 8px;

  &:focus {
    color: $color__text-input-focus;
  }
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"] {
  padding: 3px;
  padding-left: 10px;
}

textarea {
  padding-left: 3px;
  width: 100%;
}