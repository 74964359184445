table {
  margin: 0 0 1.5em;
  width: 100%;

  @include font__base-size();

  th {
    padding: 10px 20px;
    @media (max-width: $break__sm) {
      padding: 5px 5px;
    }
  }
  td {
    padding: 2px 20px;
    @media (max-width: $break__sm) {
      padding: 2px 5px;
    }
  }
}