/*
* Usage: https://smacss.com/book/type-base
**/
html {
  box-sizing: border-box;
}

*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit;
}

body {
  background: $color__background-body; /* Fallback for when there is no custom background color defined. */
}

body,
button,
input,
select,
textarea {
  color: $color__text-main;
  font-family: $font__main;
  @include font-size(1.4);
  line-height: $font__line-height-body;
  font-weight: 300;
}

b, strong {
  font-weight: 700;
}

blockquote, q {
  quotes: "" "";

  &:before,
  &:after {
    content: "";
  }
}

hr {
  background-color: $color__background-hr;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em;
}

img {
  height: auto; /* Make sure images are scaled correctly. */
  max-width: 100%; /* Adhere to container width. */
}

label {
  @include font__base-size();
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

@import "lists";
@import "tables";
@import "links";
@import "copy";
@import "headings";

@import "forms__buttons";
@import "forms__fields";
