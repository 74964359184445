ul,
ol {
  @include font__base-size();
}
dt {
  font-weight: bold;
}
dd {
  margin: 0 1.5em 1.5em;
}
